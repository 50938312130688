import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { FooterContainer, BusinessInfo, Links } from "./Footer.styles";
import ScrollToTop from "./ScrollToTop";

const Footer = ({ title, phoneNumber, navItems, email }) => {
  const hrefPhoneNumber = phoneNumber.startsWith("1")
    ? phoneNumber
    : `1-${phoneNumber}`;

  return (
    <FooterContainer>
      <BusinessInfo>
        <h1>{title}</h1>
        <div>Copyright &copy; {new Date().getFullYear()}</div>
        <div>
          <i className="fas fa-phone-square-alt"></i>
          <a className="phone-number-link" href={`tel:${hrefPhoneNumber}`}>
            {phoneNumber}
          </a>
        </div>
        <div>
          <i className="fas fa-envelope"></i>
          <a className="email-link" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </BusinessInfo>
      <Links>
        {navItems.map(item => (
          <Link key={item.id} to={item.link}>
            {item.title}
          </Link>
        ))}
      </Links>
      <ScrollToTop onClick={() => window.scrollTo(0, 0)} />
    </FooterContainer>
  );
};

Footer.defaultProps = {
  title: "Premiere Pest Patrol",
  phoneNumber: "780-499-3400",
  email: "premierepestpatrol@shaw.ca",
  navItems: [],
};

Footer.propTypes = {
  title: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
};

export default Footer;
