import styled from "styled-components";

export const FooterContainer = styled.footer`
  position: relative;
  padding: 2rem;
  background-color: ${({ theme }) => theme.red5};
  border-top: 1px solid ${({ theme }) => theme.red3};
  box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.15);
  color: white;

  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;

  ${({ theme }) => theme.media.lessThan("lg")`
      flex-flow: column nowrap;
      justify-content: center;
      align-items: space-between;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
   `}
`;

export const Links = styled.div`
  display: flex;
  flex-flow: column wrap;
  height: 125px;
  min-height: 0;

  ${({ theme }) => theme.media.lessThan("lg")`
      flex-flow: column nowrap;
      justify-content: center;
      align-items: space-between;
      text-align: center;
      width: 100%;
      height: 100%;
   `}

  a {
    text-decoration: none;
    font-size: 0.8em;
    margin: 5px 15px;
    color: white;
    font-weight: 700;

    ${({ theme }) => theme.media.lessThan("md")`
      font-size: 1em;
   `}

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: white;
      text-decoration: none;
    }
  }
`;

export const BusinessInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 75px;
  margin-right: 75px;
  border-right: 1px solid ${({ theme }) => theme.red1};

  ${({ theme }) => theme.media.lessThan("lg")`
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.red1};
      margin: 0 0 25px 0;
      padding: 0 0 25px 0;
   `}

  h1 {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 10px;
    color: white;
  }

  i,
  a.phone-number-link,
  a.email-link {
    font-size: 0.9em;
    color: white;

    ${({ theme }) => theme.media.lessThan("lg")`
      font-size: 1em;
      `}
  }

  i {
    margin-right: 5px;
  }

  a.phone-number-link {
    display: inline-block;
    margin-right: 15px;
    transition: transform 175ms ease-in;
    text-decoration: none;

    ${({ theme }) => theme.media.lessThan("lg")`
        text-align: center;
        margin-right: 0;
    `}

    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 0%;
      margin: 0 auto;
      background-color: #ffffff;
      box-shadow: 1px -1px 0px rgba(0, 0, 0, 0.5);
      transition: width 175ms cubic-bezier(0.075, 0.82, 0.165, 1);
      transform-origin: center center;
    }

    &:hover {
      :after {
        width: 100%;
        margin: 0;
      }
    }
  }
`;
