import styled from "styled-components";

const Content = styled.main`
  margin-top: 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  ${({ theme }) => theme.media.lessThan("md")`
      flex-flow: column nowrap;
    `}

  .main {
    margin-right: 66px;
    flex-basis: 65%;
    flex-grow: 1;
    flex-shrink: 0;

    ${({ theme }) => theme.media.lessThan("md")`
      margin-right: 0;
    `}
  }

  .carousel-container,
  .upper-sidebar-container {
    padding: 0 0 22px 0;
    margin-bottom: 22px;
    border-bottom: 2px solid ${({ theme }) => theme.red1};
  }
`;

export default Content;

export const SidebarPanel = styled.div`
  padding: 22px 0;
  border-top: 2px solid ${({ theme }) => theme.red1};

  &.first {
    border: none;
  }

  p {
    margin: 15px 0 0 0;
  }
`;
