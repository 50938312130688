import { lighten, darken, desaturate } from "polished";

import mediaQueries from "./mediaQueries";

const MAIN_RED = '#7d0707';
const MAIN_BROWN = "#413839";
const MAIN_WHITE = '#f1f1f1';
const MAIN_BLACK = '#333333';

export default {
  // media query system
  media: mediaQueries,

  // colours
  red1: MAIN_RED,
  red2: desaturate(0.2, lighten(0.15, MAIN_RED)),
  red3: darken(0.1, MAIN_RED),
  red4: desaturate(0.8, darken(0.1, MAIN_RED)),
  red5: desaturate(0.8, darken(0.2, MAIN_RED)),
  brown1: MAIN_BROWN,
  white1: MAIN_WHITE,
  white2: darken(0.1, MAIN_WHITE),
  black1: MAIN_BLACK
};
