import { useState, useEffect } from "react";
export { breakpoints } from "../helpers/mediaQueries";

export default function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  /**
   * Method to normalize a pixel dimension input and return a number to be compared.
   * @param {String | number} comparison can either be a string px value, number px value, or
   * px value with unit.
   */
  function normalizeComparison(comparison) {
    if (Number.isInteger(comparison) && typeof comparison !== "string") {
      // we probably entered a number so we're safe
      return comparison;
    }

    if (typeof comparison === "string") {
      // we entered a string, let's replace the units
      const withoutUnits = comparison.replace(/\D/g, "");
      return parseInt(withoutUnits, 10);
    }

    // if we make it here, something went wrong!
    return 0;
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const lessThan = (dimension, comparison) =>
    dimension < normalizeComparison(comparison);
  const greaterThan = (dimension, comparison) =>
    dimension > normalizeComparison(comparison);
  const buildHelpers = property => ({
    /**
     * Helper method for determining if the currently window dimension is
     * less than a given pixel value.
     *
     * @param {string} px A raw pixel integer or a regular string literal. Ex: '720px'
     */
    isLessThan: px => lessThan(windowDimensions[property], px),

    /**
     * Helper method for determining if the currently window dimension is
     * less than a given pixel value.
     *
     * @param {string} px A raw pixel integer or a regular string literal. Ex: '720px'
     */
    isGreaterThan: px => greaterThan(windowDimensions[property], px),
  });

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow]);

  return {
    ...windowDimensions,
    // helpers
    w: buildHelpers("width"),
    h: buildHelpers("height"),
  };
}
