import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import Header from "../Header";
import Footer from "../Footer";
import theme from "../../helpers/theme";
import { navItems } from "../../helpers/navItems";
import "./layout.css";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.black1};
    color: ${({theme}) => theme.white1};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    height: 100%;
  }

  h1,
  h2,
  h3 {
    color: ${({ theme }) => theme.red2};
  }

  a {
    color: ${({ theme }) => theme.red2};
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          companyTitle
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header title={data.site.siteMetadata.companyTitle} navItems={navItems} />

      <Helmet>
        <link href="/css/fontawesome-all.css" rel="stylesheet" />
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
        <link
          href="https://fonts.googleapis.com/css?family=Raleway|Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main
        style={{
          margin: `0 auto`,
          maxWidth: 1440,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        {children}
      </main>
      <Footer title={data.site.siteMetadata.companyTitle} navItems={navItems} />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
