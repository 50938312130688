import React from "react";
import { ScrollToTopContainer } from "./ScrollToTop.styles";

const ScrollToTop = ({ onClick = () => {} }) => {
  const alt = "Scroll to the top of the page";
  return (
    <ScrollToTopContainer alt={alt} title={alt} role="button" onClick={onClick}>
      <i className="fas fa-caret-up" />
    </ScrollToTopContainer>
  );
};

export default ScrollToTop;
