import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import pestLogo from "../../images/pest-logo.png";
import {
  MaxWidthContainer,
  TopContainer,
  NavItems,
  NavItem,
  HeaderContainer,
  HeadlineContainer,
  PestImage,
  ChildItems,
  ChildNavItem,
} from "./Header.styles";
import useDimensions from "../../hooks/useDimensions";
import useWindowDimensions, {
  breakpoints,
} from "../../hooks/useWindowDimensions";

function Navbar(props) {
  const { title, phoneNumber, linkDestination, linkAlt, navItems } = props;
  const [childNav, setChildNav] = useState(null);
  const { w } = useWindowDimensions();
  const isMobile = w.isLessThan(breakpoints.md);
  const [headerRef, headerDimensions] = useDimensions({
    initialValues: { bottom: 255 },
  });
  const hrefPhoneNumber = phoneNumber.startsWith("1")
    ? phoneNumber
    : `1-${phoneNumber}`;

  const isChildren = item => item.children && item.children.length;
  const isChildNavOpen = item => isMobile || item.id === childNav;
  const openChildNav = navId => () => setChildNav(navId);
  const closeChildNav = () => setChildNav(null);

  return (
    <HeaderContainer ref={headerRef}>
      <MaxWidthContainer>
        <TopContainer>
          <Link to={linkDestination} alt={linkAlt}>
            <PestImage
              src={pestLogo}
              alt="Premiere Pest Patrol"
              title="Premiere Pest Patrol"
            />
          </Link>
          <HeadlineContainer>
            <Link className="headline-link" to={linkDestination} alt={linkAlt}>
              <h1>{title}</h1>
            </Link>
            <i className="fas fa-phone-square-alt"></i>
            <a className="phone-number-link" href={`tel:${hrefPhoneNumber}`}>
              {phoneNumber}
            </a>
          </HeadlineContainer>
        </TopContainer>
        <NavItems>
          {navItems.map(item => (
            <NavItem
              key={item.id}
              onMouseOver={openChildNav(item.id)}
              onMouseLeave={closeChildNav}
            >
              <Link to={item.link} alt={item.title}>
                {item.title}{" "}
                {isChildren(item) ? <i className="fas fa-caret-down" /> : null}
              </Link>
              {isChildren(item) && isChildNavOpen(item) ? (
                <ChildItems
                  style={{
                    top: headerDimensions.bottom,
                  }}
                >
                  {item.children.map(child => (
                    <ChildNavItem key={child.id}>
                      <Link to={child.link} alt={child.title}>
                        {child.title}
                      </Link>
                    </ChildNavItem>
                  ))}
                </ChildItems>
              ) : null}
            </NavItem>
          ))}
        </NavItems>
      </MaxWidthContainer>
    </HeaderContainer>
  );
}

Navbar.defaultProps = {
  title: "Premiere Pest Patrol",
  phoneNumber: "780-499-3400",
  linkAlt: "Visit Premiere Pest Patrol Homepage",
  linkDestination: "/",
  navItems: [],
};

Navbar.propTypes = {
  title: PropTypes.string,
  phoneNumber: PropTypes.string,
  linkAlt: PropTypes.string,
  linkDestination: PropTypes.string,
};

export default Navbar;
