import { css } from "styled-components";

export const breakpoints = {
  xl: "1440px",
  lg: "1170px",
  md: "768px",
  sm: "450px",
};

function getSizeFromBreakpoint(breakpointValue, breakpoints = {}) {
  if (breakpoints[breakpointValue]) {
    return breakpoints[breakpointValue];
  } else if (parseInt(breakpointValue, 10)) {
    return `${breakpointValue}px`;
  } else {
    console.error("No valid breakpoint or size specified for media.");
    return "0";
  }
}

export default {
  breakpoints,
  lessThan: breakpoint => (...args) => css`
    @media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
      ${css(...args)}
    }
  `,

  greaterThan: breakpoint => (...args) => css`
    @media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
      ${css(...args)}
    }
  `,

  between: (firstBreakpoint, secondBreakpoint) => (...args) => css`
    @media (min-width: ${getSizeFromBreakpoint(
        firstBreakpoint,
        breakpoints
      )}) and (max-width: ${getSizeFromBreakpoint(
        secondBreakpoint,
        breakpoints
      )}) {
      ${css(...args)}
    }
  `,
};
