import React from "react";
import styled, { keyframes } from "styled-components";

export const MaxWidthContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 1440px;
  padding: 15px 50px 0 50px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
  overflow-x: hidden;
  ${({ theme }) => theme.media.lessThan(864)`
     padding: 10px 0 0 0;
   `}
`;

export const TopContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;

  ${({ theme }) => theme.media.lessThan("md")`
      flex-flow: column-reverse;
      justify-content: center;
      align-items: space-between;
   `}
`;

export const NavItems = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  align-self: flex-end;
  margin: 0;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.red2};
  font-size: 18px;
  font-weight: bold;
  transition: all 125ms ease-in-out;

  ${({ theme }) => theme.media.lessThan(864)`
      width: 100%;
      border-radius: 0;
      font-size: 16px;
   `}

  ${({ theme }) => theme.media.lessThan("md")`
      flex-flow: column;
      justify-content: center;
      align-items: space-between;
      text-align: center;
      width: 100%;
   `}
`;

export const NavItem = styled.div`
  padding: 10px 15px;
  position: relative;
  transition: all 125ms ease-in-out;

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }

  a:hover,
  a:active {
    color: white;
    text-decoration: underline;
  }

  ${({ theme }) => theme.media.lessThan("md")`
    width: 100%;
 `}
`;

const childItemsShowAnim = keyframes`
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
`;

const childItemsHideAnim = keyframes`
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
`;

export const ChildItems = styled.div`
  position: fixed;
  width: 300px;
  z-index: 5;
  display: flex;
  align-items: start;
  flex-flow: column;
  margin: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.red3};
  font-size: 16px;
  font-weight: bold;
  transform-origin: top left;

  &.show {
    animation: ${childItemsShowAnim} 150ms forwards ease-in;
  }

  &.hide {
    animation: ${childItemsHideAnim} 150ms forwards ease-in;
  }

  ${({ theme }) => theme.media.lessThan("md")`
    position: static;
    justify-content: center;
    align-items: space-between;
    width: 100%;
    margin-top: 15px;
`}
`;

export const ChildNavItem = styled.div`
  padding: 10px 15px;
  width: 100%;

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }

  a:hover,
  a:active {
    color: white;
    text-decoration: underline;
  }
  ${({ theme }) => theme.media.lessThan("md")`
    padding: 10px 0;
  `}
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 320px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.red2};
  background-color: ${({theme}) => theme.white1};
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23333333' fill-opacity='0.2' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"),
    linear-gradient(
      to right,
      ${({ theme }) => theme.white1},
      ${({ theme }) => theme.white2}
    );
  background-color: ${({ theme }) => theme.red1};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  color: white;
`;

export const HeadlineContainer = styled.div`
  text-align: right;

  ${({ theme }) => theme.media.lessThan("md")`
      text-align: center;
    `}

  h1 {
    user-select: none;
    font-size: 3em;
    line-height: 1em;
    margin-left: 15px;
    margin-bottom: 5px;
    display: inline-block;
    background-color: ${({ theme }) => theme.red3};
    padding: 10px 25px;
    transform: skew(-12deg, 0deg);
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.15);
    color: white;
    ${({ theme }) => theme.media.lessThan("lg")`
      font-size: 2em;
    `}

    ${({ theme }) => theme.media.lessThan("md")`
      font-size: 1.5em;
      text-align: center;
    `}

    ${({ theme }) => theme.media.lessThan("sm")`
        text-align: center;
        margin-left: 0;
    `}
  }

  a.headline-link {
    display: block;
    color: white;
  }

  a {
    text-decoration: none;
    color: white;
    border: none;

    &:visited,
    &:hover {
      color: white;
      text-decoration: none;
      color: inherit;
    }
  }

  i,
  a.phone-number-link {
    font-size: 2em;
    color: ${({theme}) => theme.black1};
    font-weight: 700;

    ${({ theme }) => theme.media.lessThan("lg")`
      font-size: 1.5em;
    `}

    ${({ theme }) => theme.media.lessThan("md")`
      font-size: 1.5em;
      `}
  }

  i {
    margin-right: 15px;
  }

  a.phone-number-link {
    display: inline-block;
    margin-top: 15px;
    margin-right: 15px;
    transition: transform 175ms ease-in;
   

    ${({ theme }) => theme.media.lessThan("md")`
        text-align: center;
        margin-right: 0;
    `}

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      margin-top: 5px;
      width: 0%;
      background-color: ${({theme}) => theme.red2};
      transition: width 175ms cubic-bezier(0.075, 0.82, 0.165, 1);
      transform-origin: center center;
    }

    &:hover {
      transform: scale(1.02);
      :after {
        width: 100%;
      }
    }
  }
`;

export const PestImage = styled(({ src, alt, title, ...rest }) => (
  <div {...rest}>
    <img src={src} alt={alt} title={title} />
  </div>
))`
  height: 100%;
  img {
    width: 160px;
    height: 160px;
  }
`;
