import styled from 'styled-components';

export const ScrollToTopContainer = styled.button`
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  background-color: ${({theme}) => theme.red3};
  color: white;
  font-size: 36px;
  padding: 10px 15px;
  margin: 25px;

  position: absolute; 
  right: 25px;
  bottom: 25px;
  z-index: 5;
  transition: all 75ms ease-in;

  &:hover,
  &:active {
    cursor: pointer;
    transform: scale(1.05);
  }

  ${({ theme }) => theme.media.lessThan("md")`
    display: none;
  `}
`