// static nav items
export const navItems = [
  { id: "home-link", title: "Home", link: "/", children: [] },
  { id: "services-link", title: "Services", link: "/services", children: [] },
  {
    id: "bedbug-link",
    title: "Bedbug Information",
    link: "/bedbug-information",
    children: [
      {
        id: "bedbug-risk-link",
        title: "Who Is At Risk For Infestations?",
        link: "/bedbug-information/who-is-at-risk",
      },
      {
        id: "bedbug-infestations-link",
        title: "Bedbug Infestations",
        link: "/bedbug-information/bedbug-infestations",
      },
      {
        id: "bedbug-prep-link",
        title: "Preparation For Bedbug Treatment",
        link: "/bedbug-information/preparation-for-bedbug-treatment",
      },
    ],
  },
  {
    id: "cockroach-link",
    title: "Cockroach Information",
    link:
      "/cockroach-information/preparing-your-home-or-office-for-cockroach-baiting",
    children: [
      {
        id: "cockroach-prep-link",
        title: "Preparing Your Home Or Office For Cockroach Baiting",
        link:
          "/cockroach-information/preparing-your-home-or-office-for-cockroach-baiting",
      },
    ],
  },
  {
    id: "contact-link",
    title: "Contact Us",
    link: "/contact-us",
    children: [],
  },
];
